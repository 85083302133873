import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { isEmpty, isEqual, toNumber } from 'lodash';

import { Button } from '@components/Button';
import TextField from '@components/Formik/TextField';

import validationSchema from './validationSchema';

import { useTouchedFormFields } from '@use-cases/clubs';
import { getClubsTranslation } from '@use-cases/clubs/helpers/getClubsTranslation';

import { isValidEmail } from '@utils/validation';

import NumberInput from '@domui-components/Formik/NumberInput';
import {
  EMAIL_LABEL,
  EMAIL_PLACEHOLDER,
  FIRST_NAME_LABEL,
  FIRST_NAME_PLACEHOLDER,
  IdentificationParameters,
  LAST_NAME_LABEL,
  LAST_NAME_PLACEHOLDER,
  MEMBER_ID_TRANSLATION,
  SEARCH_FOR_MEMBER_BTN,
  SearchFormValues,
} from '@domui-domain/clubs';

import { useTranslation } from '@external/react-i18next';

interface Props {
  classes?: string;
  filters: IdentificationParameters;
  loadingSearch: boolean;
  searchHandler: ({
    firstName,
    lastName,
    email,
    memberId,
  }: SearchFormValues) => void;
  handleFormFieldsTouched?: (isTouched: boolean) => void;
}

const SearchForm: React.FC<Props> = ({
  classes,
  filters,
  loadingSearch,
  searchHandler,
  handleFormFieldsTouched,
}) => {
  const { t } = useTranslation();
  const { checkTouchedFields } = useTouchedFormFields(handleFormFieldsTouched);

  const emailLabel = getClubsTranslation(t, EMAIL_LABEL);

  const emailPlaceholder = getClubsTranslation(t, EMAIL_PLACEHOLDER);

  const memberIdTranslation = getClubsTranslation(t, MEMBER_ID_TRANSLATION);

  const firstNameLabel = getClubsTranslation(t, FIRST_NAME_LABEL);

  const firstNamePlaceholder = getClubsTranslation(t, FIRST_NAME_PLACEHOLDER);

  const lastNameLabel = getClubsTranslation(t, LAST_NAME_LABEL);

  const lastNamePlaceholder = getClubsTranslation(t, LAST_NAME_PLACEHOLDER);

  const searchForMemberBtn = getClubsTranslation(t, SEARCH_FOR_MEMBER_BTN);

  const { id, firstName, lastName, email } = filters || {};
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const initialValues: SearchFormValues = {
    memberId: id ? toNumber(id) : '',
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
  };

  let prevFormValues: SearchFormValues;

  if (!isEqual(filters, initialValues)) {
    prevFormValues = {
      ...initialValues,
    };
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validationSchema={validationSchema(t)}
      initialTouched={{
        memberId: true,
        email: true,
        lastName: true,
        firstName: true,
      }}
    >
      {({ values, errors }) => {
        checkTouchedFields(prevFormValues || initialValues, values);

        const handleBlurEmailHandler = () => {
          if (!isEmpty(values.email) && !isValidEmail(values.email)) {
            setEmailErrorMessage(
              t('form.field.error_invalid_email', 'Invalid email address')
            );
          } else {
            setEmailErrorMessage('');
          }
        };

        return (
          <Form className={classes}>
            <TextField
              name="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              onBlurHandler={handleBlurEmailHandler}
              extraClasses={
                emailErrorMessage ? 'bg-red-100 border-red-600' : ''
              }
            />
            {emailErrorMessage && (
              <p className="form-error mb-2 -mt-4 text-xs text-red-600">
                {emailErrorMessage}
              </p>
            )}
            <NumberInput
              name="memberId"
              maxLength={16}
              label={memberIdTranslation}
              placeholder={memberIdTranslation}
            />
            <TextField
              name="firstName"
              label={firstNameLabel}
              placeholder={firstNamePlaceholder}
            />
            <TextField
              name="lastName"
              label={lastNameLabel}
              placeholder={lastNamePlaceholder}
            />
            <Button
              type="button"
              clickHandler={() => searchHandler(values)}
              full
              className="mt-8 mb-8"
              dataTestId="button-test-id"
              disabled={
                loadingSearch ||
                !Object.values(values).some(Boolean) ||
                Object.values(errors).some(Boolean)
              }
            >
              {searchForMemberBtn}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SearchForm;
