import Workflow from './base';

import { DomuiError, DynamicData } from '@domui-domain/type';

class AUTHORIZER extends Workflow {
  constructor() {
    super('authorizerwf');
  }

  async getIAMAccessLevels(requestor: string, data: DynamicData) {
    this.setAction('getIAMAccessLevels');
    this.setData({
      requestor,
      postData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async fetchEditPermission(requestor: string, data: DynamicData) {
    this.setAction('fetchEditPermission');
    this.setData({
      requestor,
      postData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async fetchClubPermission(requestor: string, data: DynamicData) {
    this.setAction('fetchClubPermission');
    this.setData({
      requestor,
      postData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async SSOToken(data: DynamicData) {
    this.setAction('sSOToken');
    this.setData({
      postData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }
}

export default AUTHORIZER;
