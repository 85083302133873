import React from 'react';

import classNames from 'classnames';
import { useField } from 'formik';

import Element, { ElementProps } from '../Element';

export interface Props extends ElementProps {
  placeholder: string;
  maxLength: number;
}

const NumberInput: React.FC<Props> = props => {
  const { name, placeholder, maxLength } = props;

  const [field, , helpers] = useField(name);

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9]*$/;
    if (value === '' || re.test(value)) {
      helpers.setValue(value);
    }
  };

  return (
    <Element {...props}>
      <input
        {...field}
        id={name}
        maxLength={maxLength}
        placeholder={placeholder}
        type="text"
        value={field.value}
        onChange={handleChange}
        className={classNames(
          'border border-gray-300 focus:border-dark-blue-400 rounded-sm',
          'w-full py-3 px-4 text-xs leading-loose focus-visible:outline-0',
          'placeholder-gray-400',
          'focus:shadow-none'
        )}
      />
    </Element>
  );
};

export default NumberInput;
