import { CSSProperties } from 'react';

import tailwind from '@styles/tailwind.js';

import { StylesConfig } from '@external/react-select';

type MultiSelectSponsorState = {
  isSelected?: boolean;
  isMulti?: boolean;
  isFocused?: boolean;
  isDisabled?: boolean;
};

export const customStyles: StylesConfig = {
  control: (provided: CSSProperties) => ({
    ...provided,
    border: `1px solid ${tailwind.theme.colors.gray['300']}`,
    borderRadius: '.125rem',
    padding: '0.1875rem 0.5rem',
    cursor: 'pointer',
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    marginTop: '1px',
  }),
  menuList: (provided: CSSProperties) => ({
    ...provided,
    border: 0,
  }),
  input: (provided: CSSProperties, state: MultiSelectSponsorState) => ({
    ...provided,
    background: state.isDisabled
      ? tailwind.theme.colors.gray['100']
      : tailwind.theme.colors.white,
  }),
  placeholder: (provided: CSSProperties, state: MultiSelectSponsorState) => ({
    ...provided,
    color: state.isDisabled
      ? tailwind.theme.colors.gray['300']
      : tailwind.theme.colors.gray['400'],
  }),
  dropdownIndicator: (
    provided: CSSProperties,
    state: MultiSelectSponsorState
  ) => ({
    ...provided,
    color: state.isDisabled
      ? tailwind.theme.colors.gray['300']
      : tailwind.theme.colors['bright-blue']['600'],
  }),
  option: (provided: CSSProperties, state: MultiSelectSponsorState) => ({
    ...provided,
    background: state.isFocused
      ? tailwind.theme.colors.gray['200']
      : tailwind.theme.colors.white,
    cursor: 'pointer',
    fontWeight: 'initial',
  }),
  indicatorSeparator: (provided: CSSProperties) => ({
    ...provided,
    backgroundColor: tailwind.theme.colors.gray['300'],
    marginTop: '6px',
    marginBottom: '6px',
    marginLeft: '8px',
  }),
  clearIndicator: () => ({
    background: tailwind.theme.colors.gray['300'],
    color: tailwind.theme.colors.white,
    borderRadius: '100%',
    lineHeight: 1,
  }),
  indicatorsContainer: (provided: CSSProperties) => ({
    ...provided,
  }),
  valueContainer: (provided: CSSProperties) => ({
    ...provided,
  }),
};
