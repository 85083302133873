import moment from 'moment';

import { MembershipForAddMember, MembershipType } from '@typings/operations';

export const getMembershipType = (isRotaractType?: boolean) => {
  const activeMembershipType = isRotaractType
    ? MembershipType.Rotaractor
    : MembershipType.Member;

  const honoraryMembershipType = isRotaractType
    ? MembershipType.RotaractHonorary
    : MembershipType.Honorary;

  return { activeMembershipType, honoraryMembershipType };
};

export const getCalendarMaxAndMinDate = (
  memberships: MembershipForAddMember[],
  clubID: string,
  selectedMembershipType?: string,
  isRotaractType?: boolean
) => {
  const dateThirtyDaysBefore = moment().subtract(30, 'd');

  const recentMembership = memberships.find(
    ({ type }) => type === selectedMembershipType
  );

  const { activeMembershipType, honoraryMembershipType } = getMembershipType(
    isRotaractType
  );

  const membershipInCurrentClub = memberships.find(
    ({ type, clubId }) => type === activeMembershipType && clubID === clubId
  );

  const hadActiveMembershipAndActiveTypeSelected =
    recentMembership?.type === activeMembershipType;

  const hadHonoraryMembershipAndHonoraryTypeSelected =
    recentMembership?.type === honoraryMembershipType &&
    recentMembership.clubId === clubID;

  const hadHonoraryMembershipAndActiveTypeSelected =
    membershipInCurrentClub &&
    selectedMembershipType === honoraryMembershipType;

  let minDate = dateThirtyDaysBefore;

  if (
    hadActiveMembershipAndActiveTypeSelected ||
    hadHonoraryMembershipAndHonoraryTypeSelected ||
    hadHonoraryMembershipAndActiveTypeSelected
  ) {
    minDate = moment(
      (recentMembership || membershipInCurrentClub)?.terminationDate
    ).add(1, 'day');
  }

  return {
    minDate: minDate.toDate(),
    maxDate: moment().toDate(),
  };
};
