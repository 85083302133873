import * as React from 'react';

export type Maybe<T> = T | null;
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum AddressType {
  Business = 'Business',
  Home = 'Home',
  HostCountry = 'HostCountry',
  Localized = 'Localized',
  Project = 'Project',
}

export type Address = {
  type: AddressType;
  lineOne?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  /** State ID (as returned by the states field) for USA and Canada. */
  stateId?: Maybe<Scalars['String']>;
  /** State/Province/Territory for countries other than USA and Canada. */
  state?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  // id?: Maybe<Scalars['String']>;
};

const IdentifyMemberResultAddress: React.FC<Partial<Address>> = ({
  lineOne,
  lineTwo,
  lineThree,
  city,
  postalCode,
  country,
  state,
  internationalProvince,
}) => (
  <address className="text-xs leading-6">
    {(lineOne || lineTwo || lineThree) && (
      <span data-cy="address-lines">
        {lineOne !== 'No Address Available' && lineOne}
        {lineTwo !== 'No Address Available' && lineTwo && (
          <>
            <br />
            {lineTwo}
          </>
        )}
        {lineThree !== 'No Address Available' && lineThree && (
          <>
            <br />
            {lineThree}
          </>
        )}
        <br />
      </span>
    )}
    <span data-cy="city">
      {city !== 'No Address Available' && city && `${city}, `}
      {state !== 'No Address Available' && state && `${state},`}{' '}
      {postalCode && `${postalCode}, `}
    </span>
    {country !== 'No Address Available' && country}
    {(city || state || postalCode || country) && <br />}
    {internationalProvince}
  </address>
);

export default IdentifyMemberResultAddress;
