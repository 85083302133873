import * as React from 'react';

import { getClubTypeName } from '@utils/getClubTypeName';

import { useTranslation } from '@external/react-i18next';

import { DIS } from '@typings/dis';
import { Enum, From, mapEnum } from '@typings/map-enum';

const IdentifyMemberResultPreviousAffiliation: React.FC<{
  clubName?: string | null;
  clubType?: string | null;
}> = ({ clubName, clubType }) => {
  const { t } = useTranslation();

  const formattedType = mapEnum(
    From.DIS,
    Enum.ClubType,
    clubType as DIS.ClubType
  );
  const clubTypeName = getClubTypeName(t, formattedType);

  const previousAffiliation = clubName
    ? `${clubName} (${clubTypeName})`
    : t('add-member.identify-member-form.club-affiliation-none', 'None');

  return <div className="break-normal">{previousAffiliation}</div>;
};

export default IdentifyMemberResultPreviousAffiliation;
