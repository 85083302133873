import * as React from 'react';

import { Button } from '../../Button';

import { useTranslation } from '@external/react-i18next';

const IdentifyMemberResultContinueButton: React.FC<{
  selectMember: () => void;
  isDisabled?: boolean;
}> = ({ selectMember, isDisabled }) => {
  const { t } = useTranslation();

  return (
    <Button
      disabled={isDisabled}
      clickHandler={selectMember}
      secondary
      small
      full
    >
      {t(
        'add-member.identify-member-form.continue-button',
        'Continue with this person'
      )}
    </Button>
  );
};

export default IdentifyMemberResultContinueButton;
