/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Member from '@domui-utils/workflow/member';

// Custom hook to handle API calls
export const useFetchPossibleSponsors = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const fetchPossibleSponsors = async (data: any) => {
    // Clear error before new request
    setLoading(true);

    try {
      if (data) {
        setError(null);
        const wfService = new Member();
        const response = await wfService.fetchPossibleSponsors(data);
        setData(response);
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return { data, loading, error, fetchPossibleSponsors };
};
