import { isNull, orderBy } from 'lodash';

import { isTerminationBeforeCurrentDate } from '@utils/membershipUtils';

import { DIS } from '@typings/dis';

import { HonoraryMembershipDataType, MembershipDataType } from './types';

export const isAllMembershipsTerminated = (
  individualData: MembershipDataType | HonoraryMembershipDataType
) => {
  if (individualData.length > 0) {
    return individualData.every(
      data =>
        !isNull(data.terminationDate) &&
        isTerminationBeforeCurrentDate(data.terminationDate)
    );
  }
  return false;
};

export const getLatestTerminatedClubForIndividual = (
  individualData: MembershipDataType | HonoraryMembershipDataType
) => {
  if (individualData.length > 1) {
    return orderBy(individualData, ({ terminationDate }) => terminationDate, [
      'desc',
    ]).slice(0, 1);
  }
  return individualData;
};

export const getMembershipByClubName = (
  clubName: string,
  memberships: MembershipDataType | HonoraryMembershipDataType,
  clubType?: DIS.ClubTypeEnum
) => {
  const clubNameLowered = clubName.toLowerCase();
  return memberships.find(membership =>
    clubType
      ? membership.clubName?.toLowerCase() === clubNameLowered &&
        membership.clubType === clubType
      : membership.clubName?.toLowerCase() === clubNameLowered
  );
};
