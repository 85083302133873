import React, { useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import moment from 'moment';

import Wizard from '@components/Formik/Wizard';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import ConfirmOfficer from '@presenters/web/pages/Clubs/ManageClubOfficers/ConfirmOfficer';
import SelectRole from '@presenters/web/pages/Clubs/ManageClubOfficers/SelectRole';
import {
  confirmAssignMemberToOfficerRoleValidationSchema,
  selectRoleValidationSchema,
} from '@presenters/web/pages/Clubs/ManageClubOfficers/validationSchema';

import AssignNonMemberToRole from '../AssignNonMemberToRole';

// import { mapValuesToCreateClubLeadershipVariables } from '@domain/clubs';
import { AssignMemberToRoleValues } from '@domain/clubs/types';

import {
  isCESNonMemberFlowEligible,
  isRotractAdvisorNonMemberFlowEligible,
} from '@use-cases/clubs/helpers/getRoleEligibility';
import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import {
  useErrorHandling,
  // useNotifications
} from '@use-cases/notifications';

// import {
//   useAssignClubLeadership,
//   useCreateIndividual,
//   useFetchClubRolesForAssignMember,
//   useUpdateIndividual,
// } from '@repositories/clubs';
// import { useUpdateContactInformation } from '@repositories/profile/hooks';

import { getYear, isFutureRY } from '@utils/datetime';
// import { getClubRoleTValue } from '@utils/getClubRoleTValue';
import { localizedNavigate } from '@utils/localized-navigate';
// import { getChannel } from '@utils/sendMessage';

import SelectOfficer from '@domui-presenters/web/pages/Clubs/ManageClubOfficers/SelectOfficer';
import {
  getClubMembersPath,
  getOfficersPagePath,
  isClubRotaractSatellite,
  isClubSatellite,
  useRoleOptions,
} from '@domui-use-cases/clubs';

import { useTranslation } from '@external/react-i18next';

// import {
//   EmailType,
//   UpdateIndividualMutationVariables,
// } from '@typings/operations';

interface AssignMemberToRoleProps extends RouteComponentProps {
  clubId: string;
  clubType: string;
  roleId: string;
  startDate: string;
  endDate: string;
}

const AssignMemberToRole: React.FC<AssignMemberToRoleProps> = ({
  clubId,
  clubType,
  roleId,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  // const { addError, addSuccess } = useNotifications();
  // const [updateContactInformation] = useUpdateContactInformation();
  // const [
  //   updateIndividual,
  //   {
  //     error: updateIndividualError,
  //     called: updateIndividualCalled,
  //     loading: updateIndividualLoading,
  //   },
  // ] = useUpdateIndividual();

  // const [
  //   createIndividual,
  //   {
  //     error: createIndividualError,
  //     called: createIndividualCalled,
  //     loading: createIndividualLoading,
  //   },
  // ] = useCreateIndividual();

  const [isFieldTouched, setIsFieldTouched] = useState<boolean>(false);
  const [isBackClubMembersPage, setIsBackClubMembersPage] = useState<boolean>(
    false
  );

  const isFirstStep = (step: number) => step === 0;

  const { isShowing, show } = useModal(window.stopBrowserNavigate);

  const modalBackHandler = () => {
    show(true);
  };
  const {
    globalHide,
    // setGlobal
  } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  const { options: data, error } = useRoleOptions(clubId, false);

  // const { data, loading, error } = useFetchClubRolesForAssignMember(
  //   clubId,
  //   roleId,
  //   clubType
  // );

  useErrorHandling(error, !!error, 'assign-member.error');

  // const [
  //   createClubLeadership,
  //   { error: errorCreateClubLeadership },
  // ] = useAssignClubLeadership();

  const stepBack = (step: number, setStep: (step: number) => void) => {
    isFirstStep(step)
      ? localizedNavigate(getOfficersPagePath(clubId))
      : setStep(step - 1);
  };

  const leavePage = (step: number, setStep: (step: number) => void) => {
    globalHide();

    if (isBackClubMembersPage) {
      localizedNavigate(getClubMembersPath(clubId));
    }
    stepBack(step, setStep);
  };

  const handleBackArrowClick = (
    step: number,
    setStep: (step: number) => void
  ) => {
    if (isFieldTouched) {
      show(true);
    } else {
      stepBack(step, setStep);
    }
  };

  const handleFormFieldsTouched = (isTouched: boolean) => {
    setTimeout(() => {
      setIsFieldTouched(isTouched);
    }, 0);
  };

  const handleCancelBtnClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    if (isFieldTouched) {
      show(true);
      setIsBackClubMembersPage(true);
    } else {
      localizedNavigate(getClubMembersPath(clubId));
    }
  };

  const role = data
    ?.filter((role: { id: string }) => role.id === roleId)
    .shift();

  if (!data || !role || error) {
    return <Loading />;
  }

  const isFutureRotaryYear = isFutureRY(getYear(endDate));

  SelectRole.schema = selectRoleValidationSchema(t);
  ConfirmOfficer.schema = confirmAssignMemberToOfficerRoleValidationSchema(
    t,
    isFutureRotaryYear
  );

  const isCreateAssignFlowEligibleRole =
    isCESNonMemberFlowEligible(roleId) ||
    isRotractAdvisorNonMemberFlowEligible(roleId);

  const handleSubmit = async (values: AssignMemberToRoleValues) => {
    // eslint-disable-next-line deprecate/member-expression, no-console
    console.log(values);
    // const { individual, email, editIndividual } = values;
    // const newEmail = email !== individual?.email ? email : null;

    // // For assign Rotaract advisor flow
    // // Step 1: if existing individual selected update the individual then create clubLeadership
    // // Step 2: if new user call createIndividual and then create leadership

    // if (isCreateAssignFlowEligibleRole) {
    //   // update individual
    //   if (editIndividual?.id) {
    //     const result = await updateIndividual({
    //       variables: {
    //         individualId: editIndividual.id,
    //         useInternalKey: true,
    //         ...(editIndividual as Omit<
    //           UpdateIndividualMutationVariables,
    //           'individualId'
    //         >),
    //       },
    //     });

    //     if (updateIndividualError) {
    //       addError(updateIndividualError.message);
    //     }

    //     if (result) {
    //       await createClubLeadership({
    //         variables: mapValuesToCreateClubLeadershipVariables(
    //           values,
    //           clubId,
    //           roleId
    //         ),
    //       });
    //     }
    //   } else {
    //     const result = await createIndividual({
    //       variables: editIndividual,
    //     });

    //     if (createIndividualError) {
    //       addError(createIndividualError.message);
    //     }

    //     const individualId = String(result.data?.createIndividual.individualId);
    //     if (editIndividual) {
    //       editIndividual.id = individualId;
    //     }
    //     if (result) {
    //       await createClubLeadership({
    //         variables: mapValuesToCreateClubLeadershipVariables(
    //           values,
    //           clubId,
    //           roleId
    //         ),
    //       });
    //     }
    //   }
    // } else {
    //   await createClubLeadership({
    //     variables: mapValuesToCreateClubLeadershipVariables(
    //       values,
    //       clubId,
    //       roleId
    //     ),
    //   });

    //   if (newEmail && !errorCreateClubLeadership) {
    //     await updateContactInformation({
    //       variables: {
    //         id: individual?.id,
    //         primaryEmail: {
    //           address: newEmail,
    //           type: EmailType.Business,
    //         },
    //       },
    //     });
    //   }
    // }
    // if (errorCreateClubLeadership) {
    //   addError(errorCreateClubLeadership.message);
    // } else {
    //   const individualName =
    //     values.individual?.name ||
    //     values.editIndividual?.name ||
    //     values.editIndividual?.localizedName;

    //   addSuccess(
    //     t(
    //       'club-add-officer.assign-role.success-message',
    //       'Success! {{name}} has been assigned the role of {{role}}.',
    //       {
    //         name: individualName,
    //         role: getClubRoleTValue(t, role.name),
    //       }
    //     ),
    //     { id: 'form.success' }
    //   );

    //   getChannel('manage-club-officers').postMessage(
    //     t(
    //       'club-officer-tab-update.message',
    //       'Please refresh this browser tab to see updated information.'
    //     )
    //   );
    //   setGlobal(false);
    //   localizedNavigate(getOfficersPagePath(clubId));
    // }
  };

  const checkPopup = (step: number, setStep: (step: number) => void) => {
    if (isShowing && (!isFieldTouched || isFirstStep(step))) {
      leavePage(step, setStep);
    }
  };

  const getConfirmationModal = (
    step: number,
    setStep: (step: number) => void
  ) => {
    return (
      <LeaveFormConfirmationModal
        isOpen={isFieldTouched && isShowing}
        closeModal={globalHide}
        onConfirm={() => {
          handleFormFieldsTouched(false);
          leavePage(step, setStep);
          window.scrollTo(0, 0);
        }}
      />
    );
  };

  const pages = isCreateAssignFlowEligibleRole
    ? [SelectOfficer, AssignNonMemberToRole, ConfirmOfficer]
    : [SelectOfficer, ConfirmOfficer];

  return (
    <Wizard
      pages={pages}
      initialValues={{
        club: { id: clubId, name: '' },
        role,
        slot: {
          start: moment(startDate),
          end: moment(endDate),
        },
        clubId,
        isRotaractClub:
          isClubRotaractSatellite(clubType) || isClubSatellite(clubType),
        clubName: '',
        isCreateAssignFlowEligible: isCreateAssignFlowEligibleRole,
        customSlot: null,
        termStartDate: startDate,
      }}
      onSubmit={handleSubmit}
      handleBackArrowClick={handleBackArrowClick}
      handleFormFieldsTouched={handleFormFieldsTouched}
      handleCancelBtnClick={handleCancelBtnClick}
      confirmationModal={getConfirmationModal}
      checkPopup={checkPopup}
    />
  );
};

export default AssignMemberToRole;
