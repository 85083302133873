import { useState } from 'react';

import Individual from '../utils/workflow/individual';

import { DomuiError, DynamicData } from '@domui-domain/type';

// Custom hook to handle API calls
export const useUpdateIndividual = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<DomuiError>(null);

  const updateIndividual = async (domId: string, payload: DynamicData) => {
    try {
      if (payload) {
        setError(null);
        setLoading(true);
        setError(null); // Clear error before new request
        const wfService = new Individual();
        const response = await wfService.updateIndividual(domId, payload);
        setData(response);
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, updateIndividual };
};
