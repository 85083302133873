import React, { useEffect } from 'react';

import DateOfBirth from '@components/Formik/DateOfBirth';
import PersonalNameForm from '@components/Formik/PersonalNameForm';
import TextField from '@components/Formik/TextField';

import { MemberDetailsFormValues } from '@domain/clubs';

import AddressFields from '@domui-components/Formik/Address';
import GenderInputs from '@domui-components/Formik/GenderInputs';
import PhoneInput from '@domui-components/Formik/PhoneInput';
import LanguageSelect from '@domui-components/Formik/Select/LanguageSelect';
import { useFetchCountries } from '@domui-hooks/useFetchCountries';
import { useFetchLanguages } from '@domui-hooks/useFetchLanguages';

import { useTranslation } from '@external/react-i18next';

interface FormProps {
  values: MemberDetailsFormValues;
  handleBlurEmailHandler: () => void;
  emailMessage: string;
  id: string;
}

const MemberDetailsForm: React.FC<FormProps> = ({
  values,
  id,
  handleBlurEmailHandler,
  emailMessage,
}) => {
  const { t } = useTranslation();

  const primaryPhoneLabels = {
    phoneCode: t('phone_extension', 'Phone Extension'),
    phoneNumber: t('phone_number', 'Phone Number'),
  };
  const isPrimaryPhoneRequired = Boolean(
    values.primaryPhone?.countryId || values.primaryPhone?.number
  );
  const {
    data: countries,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loading: countriesLoading,
    // error: getOrganizationError,
    fetchCountries,
  } = useFetchCountries();

  useEffect(() => {
    fetchCountries();
  }, []);
  const {
    data: languages,
    loading: languagesLoading,
    fetchLanguages,
  } = useFetchLanguages();

  useEffect(() => {
    fetchLanguages();
  }, []);
  return (
    <>
      <PersonalNameForm isFullNameRequired />
      <GenderInputs id={id} />
      <DateOfBirth
        isYouthFlag={false}
        isDayRequired={Boolean(values.monthOfBirth)}
        isMonthRequired={Boolean(values.dayOfBirth)}
      />
      <TextField
        name="email"
        label={t('email', 'Email')}
        onBlurHandler={handleBlurEmailHandler}
        required
        extraClasses={emailMessage ? 'bg-red-100 border-red-600' : ''}
      />
      {emailMessage && (
        <p className="form-error mb-2 -mt-4 text-xs text-red-600">
          {emailMessage}
        </p>
      )}
      <PhoneInput
        suffix="primaryPhone"
        labels={primaryPhoneLabels}
        required
        isCountryFlagRequired={isPrimaryPhoneRequired}
      />
      <AddressFields
        countryId={values.primaryAddress?.countryId}
        countryData={countries}
        name={{
          lineTwo: 'primaryAddress.lineTwo',
          lineOne: 'primaryAddress.lineOne',
          lineThree: 'primaryAddress.lineThree',
          countryId: 'primaryAddress.countryId',
          city: 'primaryAddress.city',
          stateId: 'primaryAddress.stateId',
          internationalProvince: 'primaryAddress.state',
          hasStates: 'primaryAddress.hasStates',
          postalCode: 'primaryAddress.postalCode',
        }}
        placeholder={{
          lineTwo: t('second-line-adress', 'Second line optional'),
          lineThree: t('third-line-adress', 'Third line optional'),
        }}
      />
      <LanguageSelect
        name="primaryLanguage"
        label={t('language', 'Language')}
        languages={languages}
        languagesLoading={languagesLoading}
        clearable
        required
      />
    </>
  );
};

export default MemberDetailsForm;
