// Libs
import React from 'react';

import { useField } from 'formik';

// Components
import {
  SponsorSelect as SponsorFormSelect,
  SponsorSelectProps,
} from '@domui-components/Forms/Select/SponsorSelect';

// Types
import { OptionTypeBase, ValueType } from '@external/react-select';

const SponsorSelect: React.FC<SponsorSelectProps> = props => {
  // eslint-disable-next-line react/destructuring-assignment
  const [, , helpers] = useField(props.selectName);
  return (
    <SponsorFormSelect
      {...props}
      handleChange={(individualIds: ValueType<OptionTypeBase>) =>
        helpers.setValue(individualIds)
      }
    />
  );
};

export default SponsorSelect;
