import * as React from 'react';

import Collapse from '@kunukn/react-collapse';
import classNames from 'classnames';
import { orderBy } from 'lodash';

import HonoraryMembershipCollapseHeader from '@presenters/web/pages/MemberSearch/MemberSearchList/MemberSearchResult/HonoraryMembershipCollapseHeader';

import IdentifyMemberResultAddress from './Address';
import IdentifyMemberResultContinueButton from './ContinueButton';
import IdentifyMemberResultPreviousAffiliation from './PreviousAffiliation';
import {
  getLatestTerminatedClubForIndividual,
  getMembershipByClubName,
  isAllMembershipsTerminated,
} from './utils';
import { Avatar } from '../Avatar';
import { Icon } from '../Icon';

import { isHonoraryMember, isMemberOrRotaractor } from '@use-cases/clubs';

import { useTranslation } from '@external/react-i18next';

import { HonoraryMembershipDataType, MembershipDataType } from './types';

export type Maybe<T> = T | null;
export enum ClubTypeEnum {
  RotaryClub = 'Rotary_Club',
  RotaractClub = 'Rotaract_Club',
  SatelliteClub = 'Satellite_Club',
  RotaractSatelliteClub = 'Rotaract_Satellite_Club',
}

interface IIdentifyMemberResult {
  name: string;
  email: string;
  riIndividualId?: Maybe<string>;
  photoUri?: Maybe<string>;
  phoneNumber?: string;
  activeMemberships: MembershipDataType | HonoraryMembershipDataType;
  address?: {};
  selectMember: () => void;
  expanded: boolean;
  invertSelection?: boolean;
  uniqueIdentifier: string;
  collapseHandler: (uniqueIdentifier: string) => void;
  isTerminated?: boolean;
  clubName: string;
  clubType: ClubTypeEnum;
  accessLevel?: string;
}

export const IdentifyMemberResult: React.FC<IIdentifyMemberResult> = ({
  photoUri,
  name,
  email,
  phoneNumber,
  riIndividualId,
  address,
  activeMemberships,
  selectMember,
  expanded,
  uniqueIdentifier,
  collapseHandler,
  clubName,
  clubType,
  accessLevel,
}) => {
  const { t } = useTranslation();

  let isTerminatedMemberLabel = '';
  let isTerminatedHonoraryMemberLabel = '';
  const terminatedLabel = t('member-search.terminated-label', 'Terminated');

  const isAllTerminated = isAllMembershipsTerminated(activeMemberships);

  let membersData = !isAllTerminated
    ? orderBy(
        activeMemberships.filter(resMember =>
          isMemberOrRotaractor(resMember.type)
        ),
        ({ clubName }) => clubName?.toLowerCase() || '',
        ['asc']
      )
    : [];

  let honoraryData = !isAllTerminated
    ? orderBy(
        activeMemberships.filter(resHonorary =>
          isHonoraryMember(resHonorary.type)
        ),
        ({ clubName }) => clubName?.toLowerCase() || '',
        ['asc']
      )
    : [];

  const hasActiveMembershipInCurrentClub = !!getMembershipByClubName(
    clubName,
    membersData,
    clubType
  );
  const hasActiveHonoraryMembershipInCurrentClub = !!getMembershipByClubName(
    clubName,
    honoraryData,
    clubType
  );

  if (isAllTerminated) {
    const latestTerminatedClubData:
      | MembershipDataType
      | HonoraryMembershipDataType = getLatestTerminatedClubForIndividual([
      ...activeMemberships,
    ]);

    if (isMemberOrRotaractor(latestTerminatedClubData[0].type)) {
      membersData = latestTerminatedClubData;
      isTerminatedMemberLabel = terminatedLabel;
    } else {
      honoraryData = latestTerminatedClubData;
      isTerminatedHonoraryMemberLabel = terminatedLabel;
    }
  }

  const isActiveHonoraryMembership =
    honoraryData.length >= 1 && membersData.length;
  const honoraryMembershipsTitle = isActiveHonoraryMembership
    ? t(
        'member-search.member-search-results.honorary-collapse-header.title',
        'Honorary membership(s)'
      )
    : t(
        'member-search.member-search-results.honorary-label',
        'Honorary membership(s):'
      );

  const isContinueBtnDisabled =
    accessLevel === 'District'
      ? false
      : hasActiveMembershipInCurrentClub ||
        hasActiveHonoraryMembershipInCurrentClub;

  return (
    <>
      <div
        className={classNames(
          'member-row-mobile desktop:hidden w-full p-4 -my-6',
          {
            'bg-bright-blue-100': expanded,
            'pb-8': expanded,
          }
        )}
        data-cy="member-result"
      >
        <button
          type="button"
          onClick={() => collapseHandler(uniqueIdentifier)}
          className="relative w-full flex text-left text-xs font-normal leading-6"
        >
          <Avatar photoUri={photoUri || undefined} />
          <div className="w-4/6">
            {name && (
              <h3
                className="text-md font-bold leading-6 mb-0 break-normal"
                data-cy="name"
              >
                {name}
              </h3>
            )}
            {riIndividualId && <p className="mb-0">ID: {riIndividualId}</p>}

            {address && (
              <div className="pl-16 pr-8 mt-6" data-cy="address">
                <IdentifyMemberResultAddress {...address} />
              </div>
            )}
            <div>
              {membersData.map(({ clubName, clubType }, index) => (
                <IdentifyMemberResultPreviousAffiliation
                  key={`${clubName}-${index.toString()}`}
                  clubName={clubName}
                  clubType={clubType}
                />
              ))}
              {isTerminatedMemberLabel}
            </div>
            <div>
              {honoraryData.length > 0 ? (
                <div className="mt-1">
                  <div className="mb-1">{honoraryMembershipsTitle}</div>
                  {honoraryData.map(({ clubName, clubType }, index) => (
                    <IdentifyMemberResultPreviousAffiliation
                      key={`${clubName}-${index.toString()}`}
                      clubName={clubName}
                      clubType={clubType}
                    />
                  ))}
                  {isTerminatedHonoraryMemberLabel}
                </div>
              ) : (
                ' '
              )}
            </div>
            <p
              className={classNames('text-dark-blue-400 mb-0', {
                hidden: expanded,
              })}
            >
              {t(
                'add_member.identify_member_form.view_record',
                'View Record to continue'
              )}
            </p>
            <div
              aria-hidden="true"
              className={classNames(
                'absolute right-0 absolute-vertical-center',
                { hidden: expanded }
              )}
            >
              <Icon name="arrows/down" size="16" />
            </div>
            <div
              aria-hidden="true"
              className={classNames('absolute right-0 top-0', {
                hidden: !expanded,
              })}
            >
              <Icon name="ui/close" size="20" />
            </div>
          </div>
        </button>
        <Collapse isOpen={expanded} className="collapse-css-transition">
          <div className="w-full mt-1">
            <div className="pl-16 pr-8 leading-6">
              {email && (
                <p data-cy="email" className="text-xs mb-0">
                  <a href={`mailto:${email}`}>{email}</a>
                </p>
              )}
              {phoneNumber && (
                <p className="text-xs mb-0" data-cy="phone">
                  {phoneNumber}
                </p>
              )}
            </div>
            <div className="mt-6">
              <IdentifyMemberResultContinueButton
                selectMember={selectMember}
                isDisabled={isContinueBtnDisabled}
              />
            </div>
          </div>
        </Collapse>
      </div>
      {/* Same component, version for desktop up. */}
      <div className="hidden desktop:flex items-center w-full pl-4">
        <div className="flex-1 pl-24 pr-4 text-xs leading-6 break-all">
          <Avatar
            photoUri={photoUri || undefined}
            className="-ml-24 float-left"
          />
          {name && <h3 className="text-md font-bold leading-6 mb-0">{name}</h3>}
          {riIndividualId && <p className="mb-0">ID: {riIndividualId}</p>}
          {address && <IdentifyMemberResultAddress {...address} />}{' '}
          <div className="flex-1 pr-4 text-xs leading-8">
            {membersData.map(({ clubName, clubType }, index) => (
              <IdentifyMemberResultPreviousAffiliation
                key={`${clubName}-${index.toString()}`}
                clubName={clubName}
                clubType={clubType}
              />
            ))}
            {isTerminatedMemberLabel}
          </div>
          {!isActiveHonoraryMembership ? (
            honoraryData.length > 0 && (
              <div className="flex-1 pr-4 text-xs leading-8 pt-1">
                {honoraryMembershipsTitle}
                {honoraryData.map(({ clubName, clubType }, index) => (
                  <IdentifyMemberResultPreviousAffiliation
                    key={`${clubName}-${index.toString()}`}
                    clubName={clubName}
                    clubType={clubType}
                  />
                ))}
                {isTerminatedHonoraryMemberLabel}
              </div>
            )
          ) : (
            <div className="pt-1">
              <HonoraryMembershipCollapseHeader
                title={honoraryMembershipsTitle}
                collapseInitialState={false}
              >
                <div className="flex-1 pr-4 text-xs leading-8">
                  {honoraryData.map(({ clubName, clubType }, index) => (
                    <IdentifyMemberResultPreviousAffiliation
                      key={`${clubName}-${index.toString()}`}
                      clubName={clubName}
                      clubType={clubType}
                    />
                  ))}
                  {isTerminatedHonoraryMemberLabel}
                </div>
              </HonoraryMembershipCollapseHeader>
            </div>
          )}
        </div>
        <div className="flex-1 pr-4 flex relative align-middle">
          <div>
            {email && (
              <p className="mb-0">
                <a href={`mailto:${email}`}>{email}</a>
              </p>
            )}
            {phoneNumber && <p className="mb-0">{phoneNumber}</p>}
          </div>
        </div>

        <div className="flex-1">
          <IdentifyMemberResultContinueButton
            isDisabled={isContinueBtnDisabled}
            selectMember={selectMember}
          />
        </div>
      </div>
    </>
  );
};
