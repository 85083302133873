import React, { useEffect } from 'react';

import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';
import { toNumber } from 'lodash';
import moment from 'moment';

import { Button } from '@components/Button';
import Calendar from '@components/Formik/Calendar';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import Title from '@components/Title';

import { TermToAssign } from '@domain/districts';

import {
  getTermStringFromObject,
  useModal,
  useStopBrowserNavigate,
} from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { useRemoveOfficerMutation } from '@repositories/clubs';

import { getRotaryYear, isCurrentRY } from '@utils/datetime';
import { localizedNavigate } from '@utils/localized-navigate';
import { getChannel } from '@utils/sendMessage';

import { useFetchClubLeadership } from '@domui-hooks/useFetchClubLeadership';
import {
  getBackButtonLabel,
  getOfficersPagePath,
} from '@domui-use-cases/clubs';

import { useTranslation } from '@external/react-i18next';

type clubLeadershipBody = {
  domOrgId: string;
  startDate: string;
  endDate: string;
  pageNumber: number;
  pageSize: number;
};

interface RemoveOfficerProps extends RouteComponentProps {
  clubId: string;
  leadershipId?: string;
  termStartYear: string;
}

interface FormValues {
  terminationDate: Date;
}

const RemoveOfficer: React.FC<RemoveOfficerProps> = ({
  clubId,
  leadershipId,
  termStartYear,
}) => {
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotifications();
  const {
    data: clubOfficersData,
    loading,
    error,
    fetchClubLeadership,
  } = useFetchClubLeadership();

  const startYear = toNumber(termStartYear);
  const requestBody: clubLeadershipBody = {
    domOrgId: clubId,
    startDate: `${startYear}-July-01`,
    endDate: `${startYear + 1}-June-30`,
    pageNumber: 1,
    pageSize: 10,
  };

  useEffect(() => {
    if (clubId) {
      fetchClubLeadership(requestBody);
    }
  }, [clubId, termStartYear]);

  const [removeOfficer] = useRemoveOfficerMutation();

  const { isShowing, show } = useModal(window.stopBrowserNavigate);

  const modalOnConfirmHandler = () => {
    localizedNavigate(getOfficersPagePath(clubId));
  };

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  const handleCancelBtnClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    show(true);
  };

  useErrorHandling(error?.message, !!error, 'remove-officer.error');

  if (
    loading ||
    error ||
    !clubId ||
    !leadershipId ||
    !clubOfficersData?.leadership
  ) {
    return <Loading />;
  }

  const {
    individual,
    role,
    startDate,
    endDate,
  } = clubOfficersData.leadership.filter(
    (l: { id: string }) => l.id === leadershipId
  )[0];

  const individualName = (individual: {
    firstName: string;
    middleName: string;
    lastName: string;
  }) => {
    const nameParts = [
      individual?.firstName,
      individual?.middleName,
      individual?.lastName,
    ].filter(part => part);
    return nameParts?.join(' ');
  };

  const term = getTermStringFromObject({ startDate, endDate } as TermToAssign);

  const handleSubmit = async ({ terminationDate }: FormValues) => {
    try {
      await removeOfficer({
        variables: {
          clubId,
          leadershipId,
          terminationDate: moment(terminationDate).format('YYYY-MM-DD'),
        },
      });

      addSuccess(
        t(
          'remove-leadership.success',
          'Removed {{ individualName }} from {{ roleName }} leadership position.',
          { individualName, roleName: role }
        ),
        { id: 'form.success' }
      );
      getChannel('manage-club-officers').postMessage(
        t(
          'club-officer-tab-update.message',
          'Please refresh this browser tab to see updated information.'
        )
      );

      localizedNavigate(getOfficersPagePath(clubId));
    } catch (err) {
      addError((err as Error).message, { id: 'form.error' });
    }
  };

  const isFutureRole = !isCurrentRY(startDate);
  const isStartDateInFuture = moment().isBefore(moment(startDate));

  const minDate: Date =
    isFutureRole || isStartDateInFuture
      ? moment(startDate).toDate()
      : moment().toDate();
  const maxDate: Date = moment(`${getRotaryYear()}-06-30`).toDate();

  const initialValues: FormValues = {
    terminationDate: minDate,
  };

  return (
    <div className="mt-4">
      <LinkPrevious
        path=""
        label={getBackButtonLabel(t)}
        showModal={modalBackHandler}
      />
      <LeaveFormConfirmationModal
        isOpen={isShowing}
        closeModal={globalHide}
        onConfirm={modalOnConfirmHandler}
      />
      <Title className="h2 mb-5 mt-7">
        {t('unassign-from', 'Remove from a {{selectedRole}}', {
          selectedRole: role,
        })}
      </Title>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <h5>{t('unassign-from.officer.label', 'Officer')}</h5>
          <p className="leading-md">{`${
            individual?.firstName ? individual?.firstName : ''
          } ${individual?.middleName ? individual?.middleName : ''} ${
            individual?.lastName ? individual?.lastName : ''
          }`}</p>

          {individual?.email && (
            <>
              <h5>{t('unassign-from.email.label', 'Email')}</h5>
              <p className="leading-md">{individual?.email}</p>
            </>
          )}

          {role && (
            <>
              <h5>{t('unassign-from.role.label', 'Role')}</h5>
              <p className="leading-md">{role}</p>
            </>
          )}

          {term && (
            <>
              <h5>{t('unassign-from.term.label', 'Term')}</h5>
              <p className="leading-md">{term}</p>
            </>
          )}

          {!isFutureRole && (
            <div className="custom-datepicker w-45 relative mb-4">
              <Calendar
                name="terminationDate"
                label={t('club-roster.members.select-label', 'End Date')}
                minDate={minDate}
                maxDate={maxDate}
                dateFormat="dd MMM yyyy"
                showDefaultDate
              />
            </div>
          )}

          <div className="mt-15 flex flex-col">
            <Button
              className="max-w-lg"
              dataTestId="remove-officer-action-button"
            >
              {t('remove-leadership.remove', 'Remove')}
            </Button>
            <Button
              clickHandler={handleCancelBtnClick}
              text
              className="mt-5 max-w-lg"
            >
              {t('remove-leadership.back-link', 'Cancel')}
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default RemoveOfficer;
