/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

import Member from '../utils/workflow/member';

import { DomuiError, DynamicData } from '@domui-domain/type';

// Custom hook to handle API calls
export const useFetchExistingEmail = () => {
  const [data, setData] = useState<DynamicData>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const fetchExistingEmail = async (data: any) => {
    // Clear error before new request
    try {
      if (data) {
        setLoading(true);
        setError(undefined);
        const wfService = new Member();
        const response = await wfService.FetchExistingEmail(data);
        setData(response);
        setLoading(false);
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return { data, loading, error, fetchExistingEmail };
};
