/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
// import { useEffect, useRef } from 'react';
import { useEffect } from 'react';

import { RouteComponentProps } from '@reach/router';
import moment from 'moment';
import qs from 'query-string';

import Link from '@components/Link';
import Loading from '@components/Loading';
import { Pagination } from '@components/Pagination';
import { ResultsList, ResultsListItem } from '@components/ResultsList';
import { titleTemplateParams } from '@components/Title/util';

import RosterResult from './RosterResult';

import { isEditLevel } from '@utils/access-helpers';

import SearchMembersForm from '@domui-components/Formik/SearchMembersForm';
import { CLUB_ROSTER_FILTERS } from '@domui-domain/clubs';
import { useFetchClubPermission } from '@domui-hooks/iam/useFetchClubPermission';
import Filters from '@domui-presenters/web/pages/Clubs/Members/Filters';
import { useFetchClubMembersWithFilters } from '@domui-repositories/clubs';
import { getClubDistrictFromLocal } from '@domui-sitecore/utilities/helper-functions';
import {
  getAddMemberPagePath,
  getMembershipStatusOptions,
  getOfficersPagePath,
  // isRotaractLeadership,
  useClubMembersFilters,
  useRoleOptions,
} from '@domui-use-cases/clubs';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

// import { useNotifications } from '@use-cases/notifications';

// import { useUserAccount } from '@repositories/auth/hooks';

// import { isEditLevel } from '@utils/access-helpers';
// import { closeChannel, getChannel } from '@utils/sendMessage';

// import { useMenu } from '@hooks/menu';

enum MembershipStatusFilter {
  Active = 'Active',
  Deceased = 'Deceased',
  Honorary = 'Honorary',
  HonoraryRotaractor = 'Honorary_Rotaractor',
  Rotaractor = 'Rotaractor',
  Terminated = 'Terminated',
}

interface Props extends RouteComponentProps {
  clubId: string;
  isRotaractClub?: boolean;
}

const PAGE_SIZE = 10;
const getRotaryYear = () => {
  return (moment().format('MM-DD') < '07-01'
    ? moment().year()
    : moment().year() + 1
  ).toString();
};

const ClubMembers: React.FC<Props> = ({
  clubId,
  isRotaractClub = false,
  location,
}) => {
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);

  const individualData = getClubDistrictFromLocal();
  const requestorId = individualData?.individualInfo?.id;
  // const { addInfo } = useNotifications();
  // const addInfoRef = useRef(addInfo);

  // const { user } = useAppConfig();

  const { options: roleOptions } = useRoleOptions(clubId, false);

  const allMembershipStatuses = getMembershipStatusOptions(
    t,
    true,
    isRotaractClub
  );

  const { membershipStatus } = location
    ? qs.parse(location.search)
    : { membershipStatus: null };

  const membershipStatusOption =
    (membershipStatus &&
      allMembershipStatuses.filter(
        option => option.value === membershipStatus
      )) ||
    [];

  const {
    selectedValues,
    filterValues,
    currentPage,
    setCurrentPage,
    handleSearch,
    handleApplyFilters,
    handleResetFilters,
  } = useClubMembersFilters(clubId, membershipStatusOption);

  const {
    data: iamClubData,
    loading: iamClubLOADING,
    // error: IAMError,
    fetchClubPermission,
  } = useFetchClubPermission();
  const canAddMembers = true; // isEditLevel(iamClubData?.memberships);
  const canAssignOfficers = isEditLevel(iamClubData?.leaderships);
  const payload = {
    data: [
      {
        target: `/clubs/${clubId}/membership`,
      },
      {
        target: `/clubs/${clubId}/leadership`,
        arguments: {
          targetTermYear: getRotaryYear(),
        },
      },
      {
        target: `/clubs/${clubId}`,
      },
    ],
  };
  useEffect(() => {
    fetchClubPermission(requestorId, payload);
  }, [requestorId]);

  const {
    clubMembersSearchData,
    loading,
    filters,
  } = useFetchClubMembersWithFilters(
    currentPage,
    PAGE_SIZE,
    filterValues,
    selectedValues
  );

  const membershipStatusOptions = getMembershipStatusOptions(
    t,
    canAddMembers,
    isRotaractClub
  );

  const multiSelectOptions = {
    roles: roleOptions,
    memberTypes: membershipStatusOptions,
  };

  const pageHandler = (event: React.SyntheticEvent, pageNumber: number) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  if (loading || iamClubLOADING) {
    return <Loading />;
  }

  if (!clubMembersSearchData || !clubMembersSearchData?.memberSearchInfo) {
    return null;
  }

  // const individualIdForFetch = (user?.isLoggedIn && user?.individualId) || null;

  // const { data: userData, loading: userDataLoading } = useUserAccount(
  //   individualIdForFetch
  // );

  // useEffect(() => {
  //   const addMemberChannel = getChannel('add-member');
  //   addMemberChannel.onmessage = event => {
  //     addInfoRef.current(event?.data, { id: 'members-notify' });
  //   };

  //   const manageClubOfficersChannel = getChannel('manage-club-officers');
  //   manageClubOfficersChannel.onmessage = event => {
  //     addInfoRef.current(event?.data, { id: 'members-notify' });
  //   };

  // Close broadcast channels when the component unmounts
  //   return () => {
  //     closeChannel('add-member');
  //     closeChannel('manage-club-officers');
  //   };
  // }, []);

  // const {
  //   data: submenuData,
  //   error: submenuError,
  //   loading: submenuLoading,
  // } = useMenu('menu-club-roster-actions', i18n.language);

  // const isActionLinksLoaded = !submenuError && !submenuLoading;

  // const isLeadershipRole =
  //   userData?.getIndividual.clubAffiliations.some(
  //     ({ affiliations, clubId: affilationClubId }) =>
  //       clubId === affilationClubId &&
  //       affiliations.some(item => item.__typename === 'LeadershipAffiliation')
  //   ) || false;

  // const { operationsAccess } = data.club;

  // const canAddMembers = isEditLevel(operationsAccess.memberships);
  // const canAssignOfficers = false; // isEditLevel(operationsAccess.leaderships);

  // const areSubMenuBtnsShown =
  //   !isRotaractLeadership(isLeadershipRole, data.club.clubType) &&
  //   canAddMembers &&
  //   canAssignOfficers &&
  //   isActionLinksLoaded;

  return (
    <div data-label="roster">
      <Helmet
        titleTemplate={t(
          'metadata.title.club-members',
          '{{prefix}} Club members {{suffix}}',
          { prefix, suffix }
        )}
      />
      <p className="text-small mt-4 tablet:mt-6 mb-0 tablet:mb-2">
        {t(
          'club-roster.members.description',
          'If you have questions about your club membership, please contact <a href="/club/{{clubId}}/details">Data Services</a>.',
          { clubId }
        )}
      </p>
      <div className="flex flex-col">
        {canAddMembers && (
          <div data-testid="add-a-member-btn">
            <Link
              to={getAddMemberPagePath(clubId)}
              className="mt-5 block club-roster-manage-link w-fit text-bright-blue-600 hover:underline focus:underline active:text-dark-blue-400 font-bold link-styles-off"
            >
              {t('club-roster.members.add', 'Add a Member')}
            </Link>
          </div>
        )}
        {canAssignOfficers && (
          <Link
            to={getOfficersPagePath(clubId)}
            className="mt-5 block club-roster-manage-link w-fit text-bright-blue-600 hover:underline focus:underline active:text-dark-blue-400 font-bold link-styles-off"
          >
            {t('club-roster.officers.manage', 'Manage Club Officers')}
          </Link>
        )}

        {/* {areSubMenuBtnsShown &&
          submenuData?.map(link => (
            <Link
              key={`roster-action-link-${link.id}`}
              to={link.path}
              className="mt-5 block club-roster-manage-link w-fit text-bright-blue-600 hover:underline focus:underline active:text-dark-blue-400 font-bold link-styles-off"
            >
              {link.label}
            </Link>
          ))} */}
      </div>
      <SearchMembersForm
        initialValues={filterValues}
        loading={loading}
        submitHandler={handleSearch}
        title={{
          classes:
            'mb-5 tablet:mb-0 mt-4 tablet:mt-5 tablet:normal-case capitalize',
          value: t(
            'club-roster.members.search-members-title',
            'Search club members'
          ),
        }}
        isLabelsHidden
      />
      <ResultsList
        darkBackground
        className="club-roster-result mobile:-mx-5 tablet:mx-0"
        showFiltersInContextMenu
        headerClassName="flex-wrap desktop-m:flex-nowrap"
        filtersOrder="order-4"
        summary={
          <>
            <h2 className="font-bold uppercase tracking-wide text-sm mb-0">
              {t('club-roster.members.headline', 'Club members')} (
              {clubMembersSearchData?.totalCount})
            </h2>
            {filters.memberTypes?.includes(
              MembershipStatusFilter.Terminated
            ) && (
              <span className="desktop-m:ml-3 order-5 desktop-m:order-2 font-extralight w-full desktop-m:w-auto">
                {t(
                  'club-roster.members.terminated-filter-headline',
                  'from 2015 on'
                )}
              </span>
            )}
            {filters.memberTypes?.includes(MembershipStatusFilter.Deceased) && (
              <span className="desktop-m:ml-3 order-6 desktop-m:order-3 font-extralight w-full desktop-m:w-auto">
                {t(
                  'club-roster.members.deceased-filter-headline',
                  'past 2 years'
                )}
              </span>
            )}
          </>
        }
        filters={
          <Filters
            {...{
              multiSelectOptions,
              initialFilterValues: selectedValues,
              applyFilter: handleApplyFilters,
              resetFilter: handleResetFilters,
              filtersListToShow: [
                CLUB_ROSTER_FILTERS.MEMBER_ROLES,
                CLUB_ROSTER_FILTERS.MEMBERSHIP_TYPES,
              ],
            }}
          />
        }
      >
        {clubMembersSearchData?.memberSearchInfo.map((member: any) => (
          <ResultsListItem key={member.id || ''}>
            <RosterResult
              {...member}
              clubId={clubId}
              // clubType={data.club.clubType}
              // appliedRoleFilter={filters.roles || []}
              // appliedStatusFilter={filters.memberTypes || []}
              // isLeadershipRole={isLeadershipRole}
              selectMember={() => {}}
            />
          </ResultsListItem>
        ))}
      </ResultsList>
      {clubMembersSearchData?.totalCount > 0 && (
        <Pagination
          pageSize={PAGE_SIZE}
          page={currentPage}
          totalCount={clubMembersSearchData?.totalCount}
          pageHandler={pageHandler}
        />
      )}
    </div>
  );
};

export default ClubMembers;
