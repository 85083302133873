/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash';

import { Button } from '@components/Button';
import { formatDateOfBirth } from '@components/Formik/DateOfBirth/utils';
import { FormElementErrorMessage } from '@components/Forms/FormElement';
import Loading from '@components/Loading';
import Title from '@components/Title';

import MemberDetailsForm from './Form';
import validationSchema from './validationSchema';

import { useTouchedFormFields } from '@use-cases/clubs';
// import { useErrorHandling } from '@use-cases/notifications';

import { isValidEmail } from '@utils/validation';

import {
  mapMemberDetailsInputToValues,
  MemberDetailsFormValues,
  MemberDetailsInput,
  MemberDetailsOutput,
} from '@domui-domain/clubs';
import { useFetchExistingEmail } from '@domui-hooks/useFetchExistingEmail';

import { useTranslation } from '@external/react-i18next';
// import { useSearchIndividual } from '@hooks/useSearchIndividualLazyHook';

export interface MemberDetailsProps {
  /**
   * Predefined member data to load into the form.
   */
  data: any;
  initialData: any;
  id: string;
  /**
   * Callback to return the reviewed or created member metadata.
   * @param data
   */
  submitHandler: (
    formData: MemberDetailsInput,
    dataToUpdate: MemberDetailsOutput,
    initialValues: any
  ) => void;

  handleFormFieldsTouched?: (isTouched: boolean) => void;
  isCreateAssignFlowEligible?: boolean;
  roleName?: string;
  selectedPreviousMember?: boolean;
}

const MemberDetails: React.FC<MemberDetailsProps> = ({
  data,
  id,
  initialData,
  submitHandler,
  children,
  handleFormFieldsTouched,
  isCreateAssignFlowEligible,
  roleName,
  selectedPreviousMember,
}) => {
  const { t } = useTranslation();
  const { checkTouchedFields } = useTouchedFormFields(handleFormFieldsTouched);
  const {
    data: emailData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error: emailError,
    fetchExistingEmail,
  } = useFetchExistingEmail();

  // const [searchIndividual, { error }] = useSearchIndividual();
  const [emailMessage, setEmailMessage] = useState('');
  // useErrorHandling(error?.message, !!error, 'search-individual.error');
  useEffect(() => {
    if (emailData?.isPresent) {
      setEmailMessage(
        t(
          'dis-validation-error.email-already-exists',
          'The email already exists in the system.'
        )
      );
    } else {
      setEmailMessage('');
    }
  }, [emailData]);

  if (!data) {
    return <Loading />;
  }

  let prevFormValues: MemberDetailsFormValues;

  if (!isEqual(data, initialData)) {
    prevFormValues = {
      ...initialData,
      ...formatDateOfBirth(initialData.dateOfBirth, initialData.yearOfBirth),
    };
  }

  const initialValues: MemberDetailsFormValues = {
    ...data,
    ...formatDateOfBirth(data.dateOfBirth, data.yearOfBirth),
  };
  const submit = (formValues: MemberDetailsFormValues) => {
    submitHandler(
      formValues,
      mapMemberDetailsInputToValues(formValues),
      initialValues
    );
  };

  let emailvalue = '';

  const handleBlurEmailHandler = async () => {
    if (isEmpty(emailvalue)) {
      setEmailMessage(t('form.field.error_required', 'Required'));
    } else if (!isValidEmail(emailvalue)) {
      setEmailMessage(
        t('form.field.error_invalid_email', 'Invalid email address')
      );
    } else if (!isEqual(initialValues.email, emailvalue)) {
      const payload = {
        email: emailvalue,
      };
      await fetchExistingEmail(payload);
    } else setEmailMessage('');
  };

  const getTitle = () => {
    if (selectedPreviousMember && isCreateAssignFlowEligible) {
      return t(
        'confirm-officer.add_rotaract-advisor_form.title',
        'Confirm {{name}}',
        { name: roleName }
      );
    }
    if (isCreateAssignFlowEligible) {
      return t(
        'add-new-officer-assign.add_rotaract-advisor_form.title',
        'Add New {{name}}',
        { name: roleName }
      );
    }
    return t('add_member.add_member_form.title', 'Enter Member Details');
  };

  return (
    <>
      <Title className="h2 mt-4 mb-2">{getTitle()}</Title>
      {!isCreateAssignFlowEligible && (
        <p className="mb-6">
          {data
            ? t(
                'add_member.add_member_form.check_details',
                'Please make sure member information is up-to-date.'
              )
            : t(
                'add_member.add_member_form.no_existing',
                'No existing member records match that information. You are creating a new member record.'
              )}
        </p>
      )}
      <div className="tablet:w-1/2 desktop:w-2/5">
        {!isCreateAssignFlowEligible && (
          <h4 className="uppercase mb-8">
            {t(
              'add_member.add_member_form.filters-heading',
              'Personal Details'
            )}
          </h4>
        )}

        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validateOnBlur
          validationSchema={validationSchema(t)}
        >
          {({ values, isValid, submitCount, handleSubmit, dirty }) => {
            checkTouchedFields(prevFormValues || initialValues, values);
            emailvalue = values.email || '';
            const isEmailValid = Boolean(emailMessage.length < 1);
            const isPrimaryPhone = Boolean(
              values.primaryPhone?.countryId && values.primaryPhone?.number
            );

            return (
              <>
                <MemberDetailsForm
                  id={id}
                  values={values}
                  handleBlurEmailHandler={handleBlurEmailHandler}
                  emailMessage={emailMessage}
                />
                <p className="mb-12">
                  {t(
                    'add_member.add_member_form.legal_description',
                    `Your privacy and that of club members is important to Rotary and the personal data you share with Rotary will only be used for core business purposes. These purposes include financial processing, supporting The Rotary Foundation, facilitating event planning, communicating key organizational messages and responding to your inquiries. Rotary’s privacy policy can be found at <a target="_blank" href="https://my.rotary.org/en/privacy-policy">my.rotary.org/privacy-policy</a>. Questions about this policy can be directed to <a href="mailto:privacy@rotary.org">privacy@rotary.org</a>`
                  )}
                </p>
                {!isValid && !!submitCount && (
                  <div className="mb-4">
                    <FormElementErrorMessage id="global">
                      {t(
                        'add_member.details_form.errors',
                        'The form contains errors. Please review and correct them first.'
                      )}
                    </FormElementErrorMessage>
                  </div>
                )}
                <Button
                  full
                  type={isCreateAssignFlowEligible ? 'button' : 'submit'}
                  className="mb-8"
                  clickHandler={handleSubmit}
                  disabled={
                    (!isValid && dirty) || !isPrimaryPhone || !isEmailValid
                  }
                >
                  {t('continue', 'Continue')}
                </Button>
              </>
            );
          }}
        </Formik>
        {children}
      </div>
    </>
  );
};

export default MemberDetails;
